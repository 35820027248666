<template>
  <div>
    <v-row align="stretch">
      <v-col cols="12" md="4">
        <account-settings-informations></account-settings-informations>
      </v-col>
      <v-col cols="12" md="8">
        <v-card id="account-setting-card" outlined height="100%">
          <v-tabs v-model="tab" style="box-shadow: none !important; border-bottom: 1.5px solid #e0e0e0">
            <v-tab light>
              <v-icon size="20" class="me-3"> mdi-account-lock </v-icon>
              <span> Sécurité</span>
            </v-tab>
            <v-tab light>
              <v-icon size="20" class="me-3"> mdi-bell </v-icon>
              <span> Notifications</span>
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item>
              <account-settings-security></account-settings-security>
            </v-tab-item>
            <v-tab-item>
              <account-settings-notifications></account-settings-notifications>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import "@mdi/font/css/materialdesignicons.css" // Load icons
import { ref } from "@vue/composition-api"

// Import component
import Loading from "vue-loading-overlay"
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css"
import AccountSettingsSecurity from "./AccountSettingsSecurity.vue"
import AccountSettingsCredit from "./AccountSettingsCreditCard.vue"
import AccountSettingsInformations from "./AccountSettingsInformations.vue"
import AccountSettingsNotifications from "./AccountSettingsNotifications.vue"

export default {
  components: {
    AccountSettingsSecurity,
    AccountSettingsCredit,
    AccountSettingsInformations,
    AccountSettingsNotifications,
    Loading,
  },
  data: () => ({
    grade: null,
    timer: "",
    isMounted: false,
    componentKey: 0,
    users: { account: {} },
    waitTimeout: false,
  }),
  mounted() {
    this.isMounted = true
  },
  created() {
    this.grade = localStorage.getItem("grade")
  },
  methods: {},

  setup() {
    // fetchUsers()
    const tab = ref("")

    return {
      tab,
    }
  },
}
</script>
